import React from "react"
import BackgroundImage from "../components/background-image"
import { SectionType } from "../../../types"
import { createMarkup } from "../../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const GenericSection = ({ section, index, ...props }: SectionProps) => {
  
  return (
    <div className={`relative ${section.advanced?.class || ``}`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0">{section.blurb && <div dangerouslySetInnerHTML={createMarkup(section.blurb)} />}</div>
    </div>
  )
}

export default GenericSection
